<template>
  <div>
    <div class="row items-center q-py-xs q-px-sm">
      <div class="col col-sm-auto" style="width: 150px">Date</div>
      <div class="col col-sm-auto" style="width: 150px">Pounds</div>
      <div class="col">Memo</div>
    </div>
    <q-separator />

    <div
      :style="`height: ${store.state.pageHeight - 133}px`"
      class="scroll q-pb-md"
    >
      <div v-for="pond in FeedDetail" :key="pond.id">
        <div class="bg-grey-2 text-subtitle1 text-bold q-px-sm">
          {{ pond.name }}
        </div>
        <div>
          <q-separator />
          <div v-for="(feeding, index) in pond.feedings" :key="feeding.id">
            <div class="row q-px-sm">
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ feeding.date }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ feeding.pounds }}
              </div>
              <div class="col text-caption">{{ feeding.memo }}</div>
            </div>
            <q-separator v-if="index < pond.feedings.length - 1" />
          </div>
          <q-separator />
        </div>
      </div>
      <div class="text-weight-medium q-px-sm q-pt-xs">
        Total Pounds: {{ TotalPounds }}
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import store from '@/store';
import { smartCompare, cloneObj } from '@/lib/helpers';

export default {
  name: 'TreatmentReports',
  props: {
    dates: {
      type: Object,
      required: true
    },
    pondFilter: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currPondLookupId: null,
      currPondName: '',
      dateInfo: {
        storeInfo: {
          farmId: store.state.farm.id,
          action: 'getPondEntriesByDate',
          queryProp: 'pondEntries'
        },
        from: 0,
        to: 0,
        pondGrainId: 0
      },
      dateSearchInProgress: false,
      pondsQueried: [],
      pondFilterWhenQuerying: [],
      store
    };
  },
  methods: {
    setQueriedPond() {
      this.pondsQueried.push({
        id: this.currPondLookupId,
        name: this.currPondName,
        feedings: cloneObj(store.state.dataQueries?.pondEntries?.feedings ?? [])
      });
      this.queryNextPond();
    },
    startDatesLookup() {
      this.dateSearchInProgress = true;
      this.pondsQueried = [];
      this.pondFilterWhenQuerying = cloneObj(this.pondFilter);

      const from = parseInt(date.formatDate(this.dates.from, 'X'));
      const end = date.endOfDate(this.dates.to, 'day');
      const to = parseInt(date.formatDate(end, 'X'));

      this.dateInfo.from = from;
      this.dateInfo.to = to;

      this.queryNextPond();
    },
    queryNextPond() {
      // We are using the queryData already set up for dashboard/ponds
      // This is maybe not ideal, but it does work fine

      for (const pond of this.Ponds) {
        const pondQueried = this.pondsQueried.some((x) => x.id === pond.id);
        if (!pondQueried) {
          this.dateInfo.pondGrainId = pond.grainId;
          this.currPondLookupId = pond.id;
          this.currPondName = pond.name;

          store.dispatch('queryData', this.dateInfo);
          return;
        }
      }

      this.dateSearchInProgress = false;
    }
  },
  computed: {
    FeedDetail() {
      const dateFilteredPonds =
        this.dates.dateRangeSelected === 'prev7'
          ? this.Ponds
          : this.pondsQueried;

      const ponds = [];
      for (const pond of dateFilteredPonds) {
        const pondObj = {
          name: pond.name,
          id: pond.id,
          feedings: []
        };

        for (const feeding of pond.feedings) {
          const feedingObj = {
            date: date.formatDate(feeding.date * 1000, 'MM/DD/YY'),
            id: feeding.id,
            memo: feeding.memo,
            pounds: feeding.pounds
          };

          pondObj.feedings.push(feedingObj);
        }

        ponds.push(pondObj);
      }

      return ponds.sort((a, b) => smartCompare(a.name, b.name));
    },
    Ponds() {
      const start = parseInt(date.formatDate(this.dates.from, 'X'));
      let end = date.endOfDate(this.dates.to, 'day');
      end = parseInt(date.formatDate(end, 'X'));

      const ponds = [];
      for (const pond of store.state.farm.ponds) {
        if (!this.pondFilter.includes(pond.id)) {
          continue;
        }

        const pondObj = {
          name: pond.name,
          id: pond.id,
          feedings: [],
          grainId: pond.grain_id
        };

        for (const feeding of pond.feedings) {
          if (feeding.date < start || feeding.date > end) {
            continue;
          }

          pondObj.feedings.push(feeding);
        }

        ponds.push(pondObj);
      }

      return ponds.sort((a, b) => smartCompare(a.name, b.name));
    },
    TotalPounds() {
      return this.FeedDetail.reduce((acc, curr) => {
        return acc + curr.feedings.reduce((a, c) => a + c.pounds, 0);
      }, 0).toLocaleString();
    }
  },
  watch: {
    dates() {
      this.startDatesLookup();
    },
    pondFilter() {
      if (
        JSON.stringify(this.pondFilter) !==
        JSON.stringify(this.pondFilterWhenQuerying)
      ) {
        if (!this.dateSearchInProgress) {
          this.startDatesLookup();
        }
      }
    },
    '$store.state.dataQueries': {
      handler() {
        if (!store.state.dataQueryLoading && this.dateSearchInProgress) {
          this.setQueriedPond();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
