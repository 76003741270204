<template>
  <div>
    <div class="row items-center q-py-xs q-px-sm">
      <div class="-auto" style="width: 70px">Date</div>
      <div class="col col-auto" style="width: 36px"></div>
      <div class="col col-sm-auto" style="width: 150px">Name</div>
      <div class="col-auto" style="width: 90px">Qty</div>
      <div class="col">Memo</div>
    </div>
    <q-separator />

    <div
      :style="`height: ${store.state.pageHeight - 133}px`"
      class="scroll q-pb-md"
    >
      <div v-for="pond in Treatments" :key="pond.id">
        <div class="bg-grey-2 text-subtitle1 text-bold q-px-sm">
          {{ pond.name }}
        </div>
        <div>
          <q-separator />
          <div
            v-for="(treatment, index) in pond.treatments"
            :key="treatment.id"
          >
            <div class="row q-px-sm">
              <div class="col-auto text-caption" style="width: 70px">
                {{ treatment.date }}
              </div>
              <div class="col-auto" style="width: 36px">
                <div
                  style="width: 22px; height: 18px"
                  v-html="treatment.icon"
                ></div>
              </div>
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ treatment.name }}
              </div>
              <div class="col-auto text-caption" style="width: 90px">
                {{ treatment.qty }} {{ treatment.size }}
              </div>
              <div class="col text-caption">{{ treatment.memo }}</div>
            </div>
            <q-separator v-if="index < pond.treatments.length - 1" />
          </div>
          <q-separator />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTimeOfDayIcon,
  getTimeOfDayIconFromStr,
  getTimeOfDay,
  getTimeOfDayTimeStamp
} from '@/lib/TimeOfDay';
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'TreatmentReports',
  props: {
    dates: {
      type: Object,
      required: true
    },
    pondFilter: {
      type: Array,
      required: true
    },
    treatmentFilter: {
      type: Array,
      required: true
    }
  },
  data() {
    return { getTimeOfDayIcon, store };
  },
  computed: {
    Treatments() {
      const start = parseInt(date.formatDate(this.dates.from, 'X'));
      let end = date.endOfDate(this.dates.to, 'day');
      end = parseInt(date.formatDate(end, 'X'));

      const ponds = [];
      for (const pond of store.state.farm.ponds) {
        if (!this.pondFilter.includes(pond.id)) {
          continue;
        }

        const pondObj = {
          name: pond.name,
          id: pond.id,
          treatments: []
        };

        for (const treatment of pond.treatHistory) {
          if (!this.treatmentFilter.includes(treatment.name)) {
            continue;
          }

          if (treatment.date < start || treatment.date > end) {
            continue;
          }

          const treatmentObj = {
            date: date.formatDate(treatment.date * 1000, 'MM/DD/YY'),
            icon: getTimeOfDayIconFromStr(getTimeOfDay(treatment.date)),
            id: treatment.id,
            memo: treatment.memo,
            name: treatment.name,
            qty: Math.round(treatment.qty * 100) / 100,
            size: treatment.size
          };

          pondObj.treatments.push(treatmentObj);
        }

        ponds.push(pondObj);
      }

      return ponds;
    }
  }
};
</script>

<style scoped></style>
