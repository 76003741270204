import { render, staticRenderFns } from "./FlavorPondRow.vue?vue&type=template&id=90c510ea"
import script from "./FlavorPondRow.vue?vue&type=script&lang=js"
export * from "./FlavorPondRow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QDialog,QCard,QSeparator,QMenu,QForm,QInput,QPopupProxy,QDate,QSelect,QSlider});qInstall(component, 'directives', {ClosePopup});
