<template>
  <q-layout view="hHh Lpr lFf" class="">
    <q-resize-observer @resize="onResize" debounce="100" />

    <q-header
      class="text-white"
      :class="adminBrowsing ? 'bg-accent' : 'bg-primary'"
    >
      <q-toolbar>
        <q-btn
          flat
          size="md"
          icon="menu"
          :label="$q.screen.xs ? 'Menu' : ''"
          @click="left = !left"
        />

        <div
          class="gt-sm row items-center"
          style="width: 232px; padding-left: 40px"
        >
          <img
            src="./assets/FeedXpress.png"
            alt="logo"
            style="height: 40px; width: 110px"
          />
        </div>

        <!-- Offset icons on other side -->
        <div v-if="SubscriptionExpired" style="width: 20px"></div>
        <div v-if="!OnLine" style="width: 28px"></div>
        <div v-if="adminBrowsing" style="width: 28px"></div>

        <q-space />

        <q-breadcrumbs style="font-size: 16px">
          <q-breadcrumbs-el :label="breadCrumb()" :icon="breadCrumbIcon()" />
        </q-breadcrumbs>

        <q-space />

        <q-icon
          v-if="SubscriptionExpired"
          color="white"
          size="20px"
          name="edit_off"
        >
          <q-tooltip class="bg-red" :offset="[10, 10]">
            Subscription expired
          </q-tooltip>
        </q-icon>

        <q-icon
          v-if="!OnLine"
          size="20px"
          color="white"
          name="cloud_off"
          class="q-pl-sm"
        />

        <q-icon
          v-if="adminBrowsing"
          size="20px"
          color="white"
          name="warning"
          class="q-pl-sm"
        />
      </q-toolbar>
    </q-header>

    <q-drawer
      show-if-above
      v-model="left"
      side="left"
      content-class="bg-grey-1 text-h6 shadow-2 "
    >
      <div class="lt-md" style="text-align: center; padding-top: 12px">
        <img
          src="./assets/FeedXpress.png"
          alt="logo"
          style="height: 40px; width: 110px"
        />
      </div>
      <q-separator inset class="lt-md" color="grey-7" />

      <div class="text-grey-5 text-subtitle2 q-pl-md q-py-sm">Data Entry</div>

      <q-list>
        <q-item
          v-for="link in dataEntryPages"
          :key="link.path"
          dense
          clickable
          :to="link.path"
          exact
        >
          <q-item-section v-if="link.icon" avatar style="padding: 6px 0 6px 0">
            <q-icon :name="link.icon" />
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ link.name }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

      <q-separator inset color="grey-7" />

      <div v-if="IsAdmin || IsAccountOwner">
        <div class="text-grey-5 text-subtitle2 q-pl-md q-py-sm">Admin</div>

        <q-list>
          <q-item
            v-for="link in dashboardPages"
            :key="link.path"
            dense
            clickable
            :to="link.path"
            exact
          >
            <q-item-section
              v-if="link.icon"
              avatar
              style="padding: 6px 0 6px 0"
            >
              <q-icon :name="link.icon" />
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ link.name }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-separator inset class="q-my-sm bg-grey-7" />
      </div>

      <div
        v-if="adminBrowsing"
        class="bg-accent text-center text-white q-py-sm"
      >
        Browsing as Admin
      </div>

      <div class="row items-center text-grey-5 text-subtitle2 q-px-md q-py-sm">
        Farm
        <q-space />
        <q-btn
          v-if="IsAdmin"
          outline
          label="Browse"
          size="sm"
          color="primary"
          no-caps
          @click="dialogBrowseFarms = true"
        />
      </div>

      <div v-if="farmList.length < 2" class="text-subtitle1 q-pl-md">
        {{ SelectedFarmName }}
      </div>

      <q-expansion-item v-if="farmList.length > 1" class="text-subtitle1">
        <template v-slot:header>
          <q-item-section v-if="FarmIsLoaded">
            <q-item-label>{{ SelectedFarmName }}</q-item-label>
          </q-item-section>
          <q-item-section v-if="!FarmIsLoaded">
            <q-skeleton type="text" />
          </q-item-section>
        </template>

        <q-separator inset color="grey-4" class="q-my-sm" />
        <div class="text-body2 q-pl-md">
          <div v-for="(i, index) in farmList.length" :key="index">
            <q-radio
              v-model="SelectedFarm"
              color="black"
              :label="decoder(farmList[index].farm_name)"
              :val="buildFarmIndexValue(index)"
            />
          </div>
        </div>
      </q-expansion-item>

      <q-separator inset class="q-my-sm bg-grey-7" />

      <div v-if="Locations.length > 1">
        <div class="text-grey-5 text-subtitle2 q-pl-md q-py-sm">Location</div>

        <q-expansion-item class="text-subtitle1">
          <template v-slot:header>
            <q-item-section v-if="FarmIsLoaded">
              <q-item-label>{{ SelectedLocation }}</q-item-label>
            </q-item-section>
            <q-item-section v-if="!FarmIsLoaded">
              <q-skeleton type="text" />
            </q-item-section>
          </template>

          <q-separator inset color="grey-4" class="q-my-sm" />
          <div class="text-body2 q-pl-md">
            <div v-for="location in Locations" :key="location">
              <q-radio
                v-model="selectedLocationModel"
                color="black"
                :label="location"
                :val="location"
                @input="setLocation(location)"
              />
            </div>
          </div>
        </q-expansion-item>

        <q-separator inset class="q-my-sm bg-grey-7" />
      </div>

      <div class="text-grey-5 text-subtitle2 q-pl-md q-py-sm">User</div>

      <q-item dense class="text-grey-5">
        <q-item-section avatar style="padding: 4px 0 4px 0">
          <q-icon name="account_circle" />
        </q-item-section>
        <q-item-section style="font-size: 1rem">
          <q-item-label>{{ !!User ? User.display_name : '' }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item dense clickable tag="a" :href="ACCOUNTS_LOGIN">
        <q-item-section avatar style="padding: 4px 0 4px 0">
          <q-icon name="supervised_user_circle" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Manage</q-item-label>
        </q-item-section>
      </q-item>
      <q-item dense clickable tag="a" v-on:click="logout">
        <q-item-section avatar style="padding: 4px 0 4px 0">
          <q-icon name="login" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Log out</q-item-label>
        </q-item-section>
      </q-item>

      <q-separator inset class="q-my-sm bg-grey-7" />

      <div class="row items-center text-grey-5 text-subtitle2 q-px-md q-py-sm">
        Version {{ version }}
        <q-space />
        <q-btn
          outline
          label="Reload App"
          size="sm"
          color="primary"
          no-caps
          @click="pwaReloadApp"
        />
      </div>
    </q-drawer>

    <q-page-container>
      <router-view v-if="FarmIsLoaded" />
      <q-page v-if="!FarmIsLoaded" class="flex flex-center">
        <div class="row justify-center">
          <div class="col-12 row justify-center">
            <q-spinner size="60px" color="black" :thickness="2" />
          </div>
          <div class="text-subtitle1 q-pt-md">...Loading Farm</div>
        </div>
      </q-page>
    </q-page-container>

    <finish-result />
    <delete-confirm />

    <IOSpwaDialog v-if="dialogIOSpwa" @IOSpwaConfirm="IOSpwaConfirm" />

    <!-- Browse Farms Dialog -->
    <q-dialog persistent position="top" v-model="dialogBrowseFarms">
      <q-card style="width: 450px">
        <div class="row items-center q-py-sm q-px-md">
          <q-space />
          <div class="text-h5">Browse Farms</div>
          <q-space />
          <q-btn icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator />
        <div
          class="scroll q-px-md q-py-md"
          style="max-height: calc(100vh - 120px)"
        >
          <div v-for="farm in AdminFarms" :key="farm.id">
            <div class="row items-center justify-between q-py-xs">
              <div>
                {{ decoder(farm.farm_name) }}
              </div>
              <div>
                <q-btn
                  unelevated
                  label="View"
                  color="grey-4"
                  text-color="black"
                  size="sm"
                  @click="viewFarm(farm.id)"
                />
              </div>
            </div>
            <q-separator />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import IOSpwaDialog from '@/components/general/IOSpwaConfirm.vue';

import { mapState } from 'vuex';
import AuthUtils from '@/lib/auth-utils';
import { decoder, cloneObj } from '@/lib/helpers';
import FarmRoles from '@/lib/FarmRoles';
import { colors } from 'quasar';
import store from '@/store';
import { colorPallete } from '@/styles/colors.js';
import { dataEntryPages, dashboardPages } from '@/router/StaticPages.js';

export default {
  name: 'AppBase',
  components: {
    IOSpwaDialog
  },
  data() {
    return {
      adminBrowsing: false,
      dashboardPages,
      dataEntryPages,
      decoder,
      deferredInstall: null,
      dialogIOSpwa: false,
      dialogBrowseFarms: false,
      version: process.env.VUE_APP_VERSION,
      ACCOUNTS_LOGIN: process.env.VUE_APP_ACCOUNTS_LOGIN,
      initialized: false,
      left: false,
      expanded: false,
      refreshing: false,
      registration: null,
      selectedLocationModel: null
    };
  },
  mounted() {
    this.setInitialLocation();
    this.pwaControl();
  },
  methods: {
    alertError() {
      this.$q
        .dialog({
          title: 'Error',
          message: 'There was an error with your entry.',
          ok: 'OK'
        })
        .onOk(() => {
          store.dispatch('setErrorAlert', false);
        });
    },
    alertNoService() {
      this.$q
        .dialog({
          title: 'No Network Connection',
          message: 'Entry could not be saved. Please try again later.',
          ok: 'OK'
        })
        .onOk(() => {
          store.dispatch('setNoServiceAlert', false);
        });
    },
    buildFarmIndexValue(index) {
      const farm = this.$store.state.farmList[index];
      return farm.id;
    },
    breadCrumb() {
      return this.$router.currentRoute.name;
    },
    breadCrumbIcon() {
      return (
        this.dataEntryPages.find(
          (x) => x.name === this.$router.currentRoute.name
        )?.icon ??
        this.dashboardPages.find(
          (x) => x.name === this.$router.currentRoute.name
        )?.icon ??
        ''
      );
    },
    setLocation(selectedLocation) {
      store.dispatch('setSelectedLocation', selectedLocation);
    },
    setInitialLocation() {
      const deviceLocation = localStorage.getItem('locationSelected');

      const selectedLocation =
        deviceLocation && this.Locations.includes(deviceLocation)
          ? deviceLocation
          : this.Locations.length > 0
          ? this.Locations[0]
          : 'SINGLE_LOCATION';

      this.selectedLocationModel = selectedLocation;

      store.dispatch('setSelectedLocation', selectedLocation);
    },
    logout() {
      this.$store.dispatch('logout');
    },
    onResize() {
      const height = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      store.dispatch('setPageHeight', height);
    },
    pwaReloadApp() {
      console.log('%creload app', 'color: orange');
      if (this.registration && this.registration.waiting) {
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        return;
      }

      location.reload();
    },
    pwaControl() {
      const pwaLastAskTS = localStorage.getItem('pwaLastAskTS');

      const now = +new Date();
      const oneWeekAgo = now - 604800000;

      if (this.IsIOS) {
        if (!this.IsPWA && (!pwaLastAskTS || pwaLastAskTS < oneWeekAgo)) {
          this.dialogIOSpwa = true;
        }
      }

      if (!this.IsIOS) {
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault();
          this.deferredInstall = event;

          const now = +new Date();
          const onceWeekAgo = now - 604800000;

          if (!this.IsPWA && pwaLastAskTS < oneWeekAgo) {
            this.$q
              .dialog({
                title: 'Install App?',
                message: 'App runs smoother if installed!',
                ok: {
                  icon: 'install_desktop',
                  flat: true,
                  color: 'primary',
                  label: 'Begin Install'
                },
                cancel: {
                  flat: true,
                  label: 'Cancel',
                  color: 'primary'
                },
                focus: 'ok',
                persistent: true
              })
              .onOk(() => {
                this.deferredInstall.prompt();
              })
              .onCancel(() => {
                localStorage.setItem('pwaLastAskTS', +new Date());
              });
          }
        });
      }

      window.addEventListener('appinstalled', () => {
        // Set date back so if user deletes app
        // it will immediately give install prompt again
        const now = +new Date();
        const dateTrigger = now - 704800000;
        localStorage.setItem('pwaLastAskTS', dateTrigger);
        this.deferredInstall = null;
      });

      document.addEventListener('swUpdated', this.displayUpdateNotice, {
        once: true
      });

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'refreshing' to our data originally set to false.
        console.log('%ccontroller change', 'color: red');
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    },
    IOSpwaConfirm() {
      localStorage.setItem('pwaLastAskTS', +new Date());
    },
    displayUpdateNotice(event) {
      console.log('%cupdateAvailable', 'color: red');
      this.registration = event.detail;

      if (!(this.registration?.waiting ?? false)) {
        return;
      }

      const pwaLastUpdateTS = localStorage.getItem('pwaLastUpdateTS');

      const twelveHrAgo = +new Date() - 1000 * 60 * 60 * 12;

      // Safari will send false positives
      if (pwaLastUpdateTS && parseInt(pwaLastUpdateTS) > twelveHrAgo) {
        return;
      }

      localStorage.setItem('pwaLastUpdateTS', +new Date());

      this.$q
        .dialog({
          title: 'App Update',
          ok: {
            label: 'Reload',
            color: 'primary'
          },
          cancel: {
            flat: true,
            label: 'Dismiss',
            color: 'primary'
          },
          focus: 'none',
          message: `Updated version available! App needs to reload for update.`,
          persistent: true
        })
        .onOk(async () => {
          if (!this.registration || !this.registration.waiting) return;
          // Send message to SW to skip the waiting and activate the new SW
          this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        });
    },
    viewFarm(farmId) {
      const adminBrowsing = !this.farmList.some((x) => x.id === farmId);

      if (adminBrowsing) {
        this.$q
          .dialog({
            title: 'Admin Access',
            message: `You will be browsing this farm as an admin,
             and will be able to make changes to this farm.
             Continue?`,
            ok: {
              label: 'Continue',
              color: 'accent'
            },
            cancel: {
              flat: true,
              label: 'Cancel',
              color: 'grey-4',
              textColor: 'black'
            },
            focus: 'none'
          })
          .onOk(() => {
            this.viewFarmConfirmed(adminBrowsing, farmId);
          });
      } else {
        this.viewFarmConfirmed(adminBrowsing, farmId);
      }
    },
    viewFarmConfirmed(adminBrowsing, farmId) {
      this.adminBrowsing = adminBrowsing;

      this.$store.dispatch('setSelectedFarm', {
        farm_id: farmId
      });

      this.dialogBrowseFarms = false;

      this.setInitialLocation();
    }
  },
  computed: {
    ...mapState(['farmList']),
    AdminFarms() {
      return cloneObj(store.state.adminFarms);
    },
    UserRole() {
      return AuthUtils.roleDecoder(this.$store.getters.userFarmRole);
    },
    FarmIsLoaded() {
      return this.$store.state.farmIsLoaded;
    },
    IsAccountOwner() {
      return this.UserRole === 'Account owner';
    },
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    },
    IsIOS() {
      return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      );
    },
    IsPWA() {
      return (
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone ||
        false
      );
    },
    Locations() {
      const locations = new Set();
      for (const pond of this.$store.state.farm.ponds) {
        if (pond.location_name) {
          locations.add(pond.location_name);
        } else {
          return [];
        }
      }

      return Array.from(locations);
    },
    OnLine() {
      return this.$store.state.OnLine;
    },
    SelectedFarm: {
      get() {
        return this.$store.getters.selectedFarmInfo?.farm_id ?? null;
      },
      set(newValue) {
        this.$store.dispatch('setSelectedFarm', {
          farm_id: newValue
        });

        this.adminBrowsing = false;
        this.setInitialLocation();
      }
    },
    SelectedFarmName() {
      return this.FarmIsLoaded
        ? decoder(this.$store.getters.selectedFarm?.farm_name ?? '')
        : '';
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    },
    SubscriptionExpired() {
      return this.$store.getters.subscriptionExpired && this.FarmIsLoaded;
    },
    User() {
      return this.$store.state.user;
    }
  },
  watch: {
    FarmIsLoaded() {
      this.setInitialLocation();
    },
    SubscriptionExpired() {
      if (this.SubscriptionExpired && this.FarmIsLoaded) {
        this.$q.dialog({
          title: 'Subscription Expired',
          message: `Your subscription has expired and your account is read only.`,
          ok: {
            label: 'OK',
            color: 'primary'
          },
          focus: 'none'
        });
      }
    },
    '$store.getters.selectedFarmInfo'() {
      this.$store.dispatch(
        'subscribeFarm',
        this.$store.getters.selectedFarmInfo
      );
    },
    '$store.state.OnLine'() {
      if (this.$store.state.OnLine && !this.initialized) {
        this.$store
          .dispatch('subscribeInitial', this.$store.state.user.user_id)
          .then((valid) => {
            this.initialized = valid;
          });
      }
    },
    // TODO what is this?
    '$store.state.farmDeleteDelayed'() {
      if (this.$store.state.farmDeleteDelayed === null) {
        return;
      }
      if (this.$store.state.farmList.length > 0) {
        this.$store
          .dispatch('setSelectedFarm', {
            farm_id: this.$store.state.farmList[0].id
          })
          .then();
      } else {
        this.$store
          .dispatch('setSelectedFarm', {
            farm_id: null // Not sure, this will blow up probably
          })
          .then();
      }
    },
    '$store.state.errorAlert'(newValue) {
      if (newValue) {
        this.alertError();
      }
    },
    '$store.state.noServiceAlert'(newValue) {
      if (newValue) {
        this.alertNoService();
      }
    }
  }
};
</script>

<style scoped></style>
