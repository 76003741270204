<template>
  <div>
    <div id="adjustable">
      <div class="row q-px-md q-pt-md">
        <q-resize-observer @resize="onResize" />

        <div class="q-pt-sm">
          <q-btn
            round
            icon="arrow_back"
            color="primary"
            size="md"
            padding="12px"
            @click="$emit('back')"
          />
        </div>

        <div class="col q-px-md">
          <div class="row flex-center text-center text-h5 q-px-md q-pb-md">
            {{ feeder.name }}
          </div>

          <div class="gt-xs row justify-center">
            <div>{{ FeedType }}</div>
            <div>Bal {{ numberWithCommas(feeder.balance) }}</div>
          </div>
          <div class="lt-sm">
            <div class="text-center">{{ FeedType }}</div>
            <div class="text-center">
              Bal {{ numberWithCommas(feeder.balance) }}
            </div>
          </div>
        </div>

        <div class="col-auto">
          <div class="column justify-center">
            <MasterIcon
              :percent="FeederLevelPercent"
              :iconSrc="'feeder-single.png'"
              :padding="'5px 5px 4px 5px'"
              :size="56"
            />

            <div class="text-caption text-center">
              {{ FeederLevelPercent.toFixed(0) }}%
            </div>
          </div>
        </div>
      </div>
      <q-separator />

      <div class="row items-center q-px-sm q-py-md">
        <div class="col row justify-center q-pl-sm">
          <div class="col-12 row justify-center">
            <div style="width: 240px">
              <q-btn-toggle
                v-model="toggle"
                spread
                toggle-color="primary"
                :padding="$q.screen.xs ? '6px 4px' : '6px lg'"
                :options="[
                  { label: 'Feeder Fill Ups', value: 'fillUps' },
                  { label: 'Feedings', value: 'feedings' }
                ]"
              />
            </div>
          </div>

          <div v-if="dateSearch" class="row items-center q-pt-md">
            <div class="q-pr-sm">
              {{ datesSelectedStr }}
            </div>
            <div>
              <q-btn
                icon="close"
                color="accent"
                size="sm"
                padding="2px"
                @click="setDefault"
              />
            </div>
          </div>
        </div>

        <div class="col-auto q-pl-md">
          <div>
            <q-btn
              color="primary"
              size="sm"
              padding="7px"
              style="width: 92px"
              @click="fillFeeder"
            >
              <div style="font-size: 11px">Fill Feeder</div>
            </q-btn>
          </div>
          <div style="padding-top: 10px">
            <q-btn
              color="grey-4"
              text-color="black"
              size="sm"
              padding="7px"
              style="width: 92px"
              @click="reconcileFeeder"
            >
              <div style="font-size: 11px">Reconcile</div>
            </q-btn>
          </div>
          <div style="padding-top: 10px">
            <q-btn
              :color="!dateSearch ? 'grey-4' : 'accent'"
              :text-color="!dateSearch ? 'black' : 'white'"
              size="sm"
              padding="7px"
              style="width: 92px"
              :loading="DataQueryLoading"
              @click="dialogDates = true"
            >
              <div style="font-size: 11px">Date Search</div>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-separator color="black" />

    <div class="row text-bold q-py-xs">
      <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
      <div class="col text-center">
        <div v-if="toggle === 'fillUps'">
          <div class="gt-sm">Bin</div>
          <div class="lt-md">Bin / Type</div>
        </div>
        <div v-if="toggle === 'feedings'">
          <div class="gt-sm">Pond</div>
          <div class="lt-md">Pond/Type</div>
        </div>
      </div>
      <div class="col-auto text-center" style="width: 72px">Lbs</div>
      <div class="gt-sm col text-center">Feed Type</div>
      <div class="gt-xs col text-center">Note</div>
      <div class="lt-sm col-auto" style="width: 42px">Note</div>
      <div class="col-auto" :style="`padding-right: ${customPadding}px`">
        <div v-if="toggle === 'fillUps'" :style="`width: ${btnWidth}px`"></div>
      </div>
    </div>
    <q-separator color="black" />

    <div id="entries-container">
      <q-resize-observer @resize="setCustomPadding" />
      <div
        v-if="FeederEntries.length < 1"
        class="text-center text-h5 text-grey-6 q-pa-md"
      >
        No Entries
      </div>

      <q-virtual-scroll
        v-if="FeederEntries.length"
        :style="`height: ${usableHeight}px`"
        :items="FeederEntries"
        separator
      >
        <template v-slot="{ item }">
          <FeederDetailRow
            :feeder="feeder"
            :entry="item"
            :toggle="toggle"
            @edit="editEntry"
          />
        </template>
      </q-virtual-scroll>
    </div>

    <!-- Fill Feeder Dialog -->
    <q-dialog persistent position="top" v-model="dialogFillFeeder">
      <q-card style="width: 450px" class="q-pa-md">
        <q-form @submit="submitEntry">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="transaction.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="transaction.from_account"
              label="Select Bin"
              dense
              filled
              :options="BinOptions"
              emit-value
              map-options
              :disable="action === 'edit'"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Bin']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="transaction.pounds"
              label="Lbs"
              step="any"
              min="0"
              mask="#####"
              type="number"
              @input="setTransactionTons()"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || '']"
            />
          </div>

          <div v-if="action === 'edit'" class="q-pb-sm">
            <q-select
              v-model="transaction.feed_type"
              label="Select Feed Type"
              dense
              filled
              :options="FeedTypes"
              emit-value
              map-options
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Feed Type']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              autogrow
              v-model="transaction.memo"
              label="Note"
            />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'edit'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteEntry"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit' && !$q.screen.xs ? 'Submit Edit' : 'Submit'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Reconcile Dialog -->
    <q-dialog persistent position="top" v-model="dialogReconcile">
      <q-card style="width: 450px" class="q-pa-md">
        <q-form @submit="submitEntry">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="transaction.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="row q-pb-sm">
            <div>
              <q-btn
                :icon="reconcileAdd ? 'add' : 'remove'"
                :color="reconcileAdd ? 'positive' : 'accent'"
                size="md"
                class="q-mr-sm"
                padding="8px 10px"
                @click="reconcileAdd = !reconcileAdd"
              />
            </div>
            <div class="col">
              <q-input
                filled
                dense
                v-model.number="transaction.pounds"
                label="Lbs"
                step="any"
                min="0"
                mask="#####"
                type="number"
                lazy-rules
                hide-bottom-space
                :rules="[(val) => isNumValid(val) || '']"
              />
            </div>
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              autogrow
              v-model="transaction.memo"
              label="Note"
            />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'edit'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteEntry"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit'
                  ? 'Submit Edit'
                  : action === 'create'
                  ? 'Submit'
                  : 'TODO'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog full-height persistent position="top" v-model="dialogDates">
      <DatePicker
        :dateRangeSelection="dateRangeSelection"
        @datesSelected="datesSelected"
      />
    </q-dialog>
  </div>
</template>

<script>
import FeederDetailRow from '@/components/feed/FeederDetailRow.vue';
import MasterIcon from '@/components/icons/MasterIcon.vue';
import DatePicker from '@/components/general/DatePicker.vue';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';

export default {
  name: 'FeederDetail',
  components: {
    FeederDetailRow,
    MasterIcon,
    DatePicker
  },
  props: {
    feeder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      btnWidth: 36,
      customPadding: 14,
      dateRangeSelection: 'prev90',
      dialogDates: false,
      dialogFillFeeder: false,
      dialogReconcile: false,
      dateCopy: null,
      dateCopyStr: null,
      dateSearch: false,
      datesSelectedCopy: null,
      datesSelectedStr: '',
      isDateValid,
      isNumValid,
      numberWithCommas,
      reconcileAdd: true,
      poundsCopy: null,
      store,
      toggle: 'fillUps',
      transaction: {},
      transactionTons: null,
      transactionPricePerTon: null,
      usableHeight: 1000
    };
  },
  mounted() {},
  methods: {
    datesSelected(dates) {
      this.dateRangeSelection = dates.dateRangeSelected;
      this.datesSelectedCopy = cloneObj(dates);
      const fromStr = date.formatDate(dates.from, 'MM/DD/YYYY');
      const toStr = date.formatDate(dates.to, 'MM/DD/YYYY');
      this.datesSelectedStr = `${fromStr} - ${toStr}`;

      const from = parseInt(date.formatDate(dates.from, 'X'));
      const end = date.endOfDate(dates.to, 'day');
      const to = parseInt(date.formatDate(end, 'X'));

      const dateInfo = {
        storeInfo: {
          farmId: this.$store.state.farm.id,
          action: 'getFeederEntriesByDate',
          queryProp: 'feederEntries'
        },
        from,
        to,
        feederGrainId: this.feeder.grain_id
      };

      store.dispatch('queryData', dateInfo);
      this.dialogDates = false;

      this.dateSearch = true;
    },
    deleteEntry() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            unelevated: true,
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteEntryConfirmed();
        });
    },
    deleteEntryConfirmed() {
      const transaction = cloneObj(this.transaction);
      transaction.storeInfo.action = 'deleteFeederEntry';
      transaction.storeInfo.category = 'feederEntries';
      transaction.storeInfo.feederId = this.feeder.id;
      transaction.storeInfo.feederGrainId = this.feeder.grain_id;

      transaction.storeInfo.feederBal =
        this.dialogReconcile && !this.reconcileAdd
          ? parseFloat(this.feeder.balance) + this.poundsCopy
          : parseFloat(this.feeder.balance) - this.poundsCopy;

      guidMatchHelper(transaction, this.action);

      // Add details to update bin info
      if (!this.dialogReconcile) {
        const bin = this.BinOptions.find(
          (x) => x.value === transaction.from_account
        );

        transaction.storeInfo.binId = bin.id;
        transaction.storeInfo.binGrainId = bin.value;
        transaction.storeInfo.binBal =
          parseFloat(bin.balance) - this.poundsCopy;
      }

      transaction.storeInfo.reconcile = this.dialogReconcile;

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      if (this.dateSearch) {
        this.datesSelected(this.datesSelectedCopy);
      }

      this.dialogFillFeeder = false;
      this.dialogReconcile = false;
    },
    editEntry(entry) {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(entry)
      };

      this.transactionTons =
        Math.round((this.transaction.pounds / 2000) * 100) / 100;

      this.transactionPricePerTon =
        !this.transaction.price_per_pound ||
        this.transaction.price_per_pound === ''
          ? null
          : Math.round(this.transaction.price_per_pound * 2000 * 100) / 100;

      this.dateCopy = this.transaction.date;
      this.transaction.date = date.formatDate(
        this.transaction.date * 1000,
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.transaction.date;
      this.poundsCopy = parseFloat(this.transaction.pounds);

      if (!this.transaction.bin_name) {
        this.dialogReconcile = true;
        this.reconcileAdd =
          this.transaction.to_account === this.feeder.grain_id ? true : false;
      } else {
        this.dialogFillFeeder = true;
      }
    },
    fillFeeder() {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.transactionTons = null;
      this.transactionPricePerTon = null;
      this.poundsCopy = 0;

      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');

      this.dialogFillFeeder = true;
    },
    reconcileFeeder() {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');
      this.poundsCopy = 0;

      this.dialogReconcile = true;
    },
    setTransactionLbs() {
      this.transaction.pounds = this.transactionTons * 2000;
    },
    setTransactionTons() {
      this.transactionTons =
        Math.round((this.transaction.pounds / 2000) * 100) / 100;
    },
    setTransactionPricePerLb() {
      this.transaction.price_per_pound =
        !this.transactionPricePerTon || this.transactionPricePerTon === ''
          ? null
          : Math.round((this.transactionPricePerTon / 2000) * 1000) / 1000;
    },
    submitEntry() {
      const transaction = cloneObj(this.transaction);

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.action === 'create') {
        transaction.id = 0;
      }

      transaction.to_account = this.feeder.grain_id;

      if (this.dialogReconcile) {
        const grainId = this.$store.state.farm.settings.loss.grain_id;

        if (this.reconcileAdd) {
          transaction.from_account = grainId;
        }

        if (!this.reconcileAdd) {
          transaction.to_account = grainId;
          transaction.from_account = this.feeder.grain_id;
        }

        transaction.feed_type = null;
        transaction.feed_type_name = null;
      }

      this.submitEntryHelper(transaction);
      store.dispatch('publish', transaction);

      this.dialogFillFeeder = false;
      this.dialogReconcile = false;

      this.$q.notify({
        message: `Entry ${this.action === 'create' ? 'Added' : 'Updated'}`,
        icon: 'check',
        color: 'primary'
      });

      if (this.dateSearch) {
        this.datesSelected(this.datesSelectedCopy);
      }
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action =
        this.action === 'create' ? 'createFeederEntry' : 'updateFeederEntry';
      transaction.storeInfo.feederBal =
        parseFloat(this.feeder.balance) +
        (transaction.pounds - this.poundsCopy);
      transaction.storeInfo.category = 'feederEntries';
      transaction.storeInfo.feederId = this.feeder.id;
      transaction.storeInfo.feederGrainId = this.feeder.grain_id;

      guidMatchHelper(transaction, this.action);

      const bin = this.BinOptions.find(
        (x) => x.value === transaction.from_account
      );

      if (!this.dialogReconcile && this.action === 'create') {
        transaction.bin_name = bin.name;
        transaction.feed_type = bin.feedTypeId;
        transaction.feed_type_name = bin.feedTypeName;
      }

      if (!this.dialogReconcile) {
        // Add details to update bin info
        transaction.storeInfo.binId = bin.id;
        transaction.storeInfo.binGrainId = bin.value;
        transaction.storeInfo.binBal =
          parseFloat(bin.balance) + (this.poundsCopy - transaction.pounds);
      }

      transaction.storeInfo.reconcile = this.dialogReconcile;
      transaction.feeder_name = this.feeder.name;
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      this.usableHeight = store.state.pageHeight - 82 - adjustableHeight;
    },
    setCustomPadding() {
      const div = document.getElementById('entries-container');

      let hasScrollBar = div.scrollHeight < this.FeederEntries.length * 40;

      this.customPadding = hasScrollBar ? this.ScrollbarWidth : 0;
      this.btnWidth = this.$q.screen.gt.sm ? 44 : 36;
    },
    setDefault() {
      this.dialogDates = false;
      this.dateSearch = false;
    },
    validatePrice(val) {
      return (val && isNumValid(val)) || !val;
    }
  },
  computed: {
    FeederEntries() {
      return this.dateSearch
        ? this.FeederQueriedEntries
        : this.toggle === 'feedings'
        ? this.feeder.feedings
        : this.feeder.fillUps;
    },
    FeederLevelPercent() {
      return getPercentage(this.feeder.balance, this.feeder.capacity);
    },
    FeederQueriedEntries() {
      return this.toggle === 'fillUps'
        ? this.$store.state.dataQueries.feederEntries?.fillUps ?? []
        : this.$store.state.dataQueries.feederEntries?.feedings ?? [];
    },
    DataQueryLoading() {
      return store.state.dataQueryLoading;
    },
    FeedType() {
      let feedType = '---';
      for (const entry of this.feeder.fillUps) {
        if (entry.bin_name) {
          feedType = entry.feed_type_name;
          break;
        }
      }

      return feedType;
    },
    FeedTypes() {
      const feedTypes = [];
      for (const feedType of store.state.farm.feedTypes) {
        if (!feedType.archived) {
          feedTypes.push({
            label: feedType.name,
            value: feedType.id
          });
        }
      }
      return feedTypes;
    },
    BinOptions() {
      const bins = store.state.farm.bins.filter((x) => !x.archived);

      const options = [];
      for (const bin of bins) {
        if (!bin.in_service) {
          continue;
        }

        // get the feed type of the bin - which is the feed type of the latest delivery
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of bin.deliveries) {
          if (entry.mill_name) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        const label =
          this.action === 'edit' ? bin.name : bin.name + ' -- ' + feedTypeName;

        options.push({
          id: bin.id,
          balance: bin.balance,
          name: bin.name,
          label,
          value: bin.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    ScrollbarWidth() {
      return getScrollbarWidth();
    }
  }
};
</script>

<style></style>
