<template>
  <div class="col-12">
    <q-card flat class="row bg-grey-2 q-px-md q-pt-md q-pb-sm q-mb-md">
      <div class="column items-center justify-center">
        <MasterIcon
          :percent="BinLevelPercent"
          :iconSrc="'bin-single.png'"
          :padding="'5px 5px 4px 5px'"
          :size="56"
        />

        <div class="text-caption text-center">
          {{ BinLevelPercent.toFixed(0) }}%
        </div>
      </div>

      <div class="col row justify-center q-pl-md">
        <div class="col-xs-12 col-sm-6 row justify-between">
          <div class="col-12">
            <q-btn
              v-if="buttons"
              color="primary"
              class="full-width"
              padding="4px"
              @click="$emit('goToBinEntries', bin)"
            >
              <span class="text-subtitle1 text-center">
                {{ decoder(bin.name) }}
              </span>
            </q-btn>

            <div v-if="!buttons" class="text-center text-h6">
              {{ decoder(bin.name) }}
            </div>
          </div>

          <div class="col-12 row flex-center text-subtitle1 q-pt-xs">
            <div>{{ numberWithCommas(bin.balance) }} lbs</div>
          </div>

          <div
            v-if="!bin.in_service"
            class="col-12 row justify-center text-accent q-pt-xs"
          >
            <div>Out of Service</div>
          </div>
        </div>
      </div>

      <div v-if="fillBtn" class="row items-center q-pb-sm">
        <q-btn
          unelevated
          color="primary"
          label="Fill"
          class="full-width"
          @click="$emit('fillBin', bin)"
        />
      </div>
    </q-card>
  </div>
</template>

<script>
import MasterIcon from '@/components/icons/MasterIcon.vue';

import { numberWithCommas, getPercentage, decoder } from '@/lib/helpers';

export default {
  name: 'BinOverview',
  components: {
    MasterIcon
  },
  props: {
    bin: {
      type: Object,
      required: true
    },
    buttons: {
      type: Boolean,
      required: true
    },
    toggle: {
      type: Boolean,
      default: false
    },
    fillBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      decoder,
      numberWithCommas
    };
  },
  mounted() {},
  computed: {
    BinLevelPercent() {
      return getPercentage(this.bin.balance, this.bin.capacity);
    }
  }
};
</script>

<style></style>
