<template>
  <div>
    <q-resize-observer @resize="onResize" />

    <div class="row items-center no-wrap q-py-sm q-px-md">
      <q-btn
        unelevated
        label="Overview"
        size="md"
        color="primary"
        class="gt-xs q-mr-sm"
        @click="dialogOverview = true"
      />
      <q-btn
        unelevated
        icon="list_alt"
        size="md"
        color="primary"
        class="lt-sm q-mr-sm"
        @click="dialogOverview = true"
      />
      <q-btn
        unelevated
        label="Settings"
        size="md"
        color="primary"
        class="gt-xs"
        @click="dialogFeedSettings = true"
      />
      <q-btn
        unelevated
        icon="settings"
        size="md"
        color="primary"
        class="lt-sm q-mr-sm"
        @click="dialogFeedSettings = true"
      />
      <q-space />

      <div :class="$q.screen.xs ? 'q-pr-md' : 'q-pr-lg'">
        <q-toggle
          dense
          v-close-popup
          v-model="displayCharts"
          label="Charts"
          color="primary"
          :size="$q.screen.xs ? 'md' : 'lg'"
        />
      </div>

      <div class="">
        <q-toggle
          dense
          v-close-popup
          v-model="displayOverfed"
          label="Feed Eval"
          color="accent"
          :size="$q.screen.xs ? 'md' : 'lg'"
        />
      </div>
    </div>
    <q-separator />

    <div :style="`height: ${useableHeight}px`" class="scroll">
      <div class="row justify-center q-px-md q-pt-lg">
        <div
          class="col-12 row items-center text-subtitle2 text-bold"
          style="max-width: 600px"
        >
          <div v-for="bin in BinIndicators" :key="bin.id" class="col-12">
            <BinOverview
              :bin="bin"
              :buttons="false"
              :fillBtn="true"
              @fillBin="fillBin"
            />
          </div>

          <FeederOverview
            :feeder="FeederSelected"
            :buttons="false"
            :fillBtn="true"
            @fillFeeder="fillFeeder"
          />
        </div>
      </div>

      <div v-if="!displayCharts" class="row justify-center q-px-md">
        <div
          class="col-12 row items-center text-subtitle2 text-bold"
          style="max-width: 600px"
        >
          <div class="col-12 row items-center q-py-xs">
            <div v-if="!displayCharts" class="col-7 row">
              <div class="col q-pl-sm">Pond</div>
              <div class="col-7 row">
                <div class="col-6 q-pl-sm">Last</div>
                <div class="col-auto q-pl-sm">
                  {{ CurrentLabel }}
                </div>
              </div>
            </div>
          </div>

          <q-separator class="full-width" color="grey-5" />
        </div>
      </div>

      <div class="row justify-center q-px-md">
        <div class="col-12 row items-center" style="max-width: 600px">
          <div v-if="!displayCharts" class="col row items-center">
            <div class="col-7 row q-py-xs">
              <div class="col q-pl-sm">Totals</div>
              <div class="col-7 row">
                <div class="col-6 q-pl-sm">{{ LastTotal }}</div>
                <div class="col-6 q-pl-sm">{{ CurrentTotal }}</div>
              </div>
            </div>
            <q-separator class="full-width" color="grey-5" />
          </div>

          <div v-if="displayCharts" class="col-12 q-pt-sm q-pb-lg">
            <q-card>
              <div class="text-h6 q-pl-md q-py-sm">Totals</div>
              <q-separator class="full-width" />
              <div class="row text-subtitle1 q-px-md q-py-sm">
                <div class="col-6">Last <span> - </span> {{ LastTotal }}</div>
                <div class="col-6">
                  <span v-if="feedDividerOption === 1"> Current </span>
                  <span v-if="feedDividerOption === 0"> Today </span>
                  <span> - </span> {{ CurrentTotal }}
                </div>
              </div>
              <q-separator class="full-width" />
              <div
                :id="`chart-container-total`"
                style="height: 160px"
                class="q-pa-xs"
              >
                <FeedChart :chartData="ChartTotals" :chartId="'totals-chart'" />
              </div>
            </q-card>
          </div>
        </div>
      </div>

      <div id="ponds-container" class="scroll q-px-md q-pb-xl">
        <div class="row justify-center">
          <div
            v-for="pond in Ponds"
            :key="pond.name"
            class="col-12 row justify-center"
          >
            <div :class="displayCharts && 'q-pb-lg'" style="width: 600px">
              <FeedPondRow
                :pond="pond"
                :FeederSelected="FeederSelected"
                :displayCharts="displayCharts"
                :displayOverfed="displayOverfed"
                :resizeWatcher="resizeWatcher"
                :feedDividerOption="feedDividerOption"
                :lastFeedingDates="lastFeedingDates"
                @setLastFeedingDate="setLastFeedingDate"
              />
              <q-separator v-if="!displayCharts" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Fill Bin Dialog -->
    <q-dialog full-height persistent position="top" v-model="dialogFillBin">
      <q-card style="width: 450px" class="q-pa-md">
        <div :style="`max-height: ${useableHeight + 50}px`" class="scroll">
          <div v-if="selectedBin">
            <BinOverview :bin="selectedBin" :buttons="false" />
          </div>

          <FillBin
            :transactionObj="transaction"
            :action="'create'"
            :bin="selectedBin"
            @close="dialogFillBin = false"
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- Fill Feeder Dialog -->
    <q-dialog full-height persistent position="top" v-model="dialogFillFeeder">
      <q-card style="width: 450px">
        <div class="text-h6 text-center q-py-sm">
          {{ FeederSelected.label }}
        </div>
        <q-separator />
        <div :style="`max-height: ${useableHeight + 40}px`" class="scroll">
          <q-form @submit="fillFeederSubmit">
            <div class="q-pa-lg">
              <div class="q-pb-sm">
                <q-input
                  outlined
                  dense
                  label="Date"
                  v-model="transaction.date"
                  lazy-rules
                  :rules="[(val) => isDateValid(val) || 'Invalid Date']"
                  hide-bottom-space
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer" color="primary">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="q-pb-sm">
                <q-select
                  v-model="transaction.from_account"
                  label="Select Bin"
                  dense
                  outlined
                  :options="BinOptions"
                  emit-value
                  map-options
                  lazy-rules
                  hide-bottom-space
                  :rules="[(val) => !!val || 'Please Select A Bin']"
                />
              </div>

              <div>
                <q-input
                  outlined
                  dense
                  autogrow
                  v-model="transaction.memo"
                  label="Note"
                />
              </div>
            </div>
            <q-separator />

            <div class="row items-center justify-around text-caption q-py-xs">
              <div>Last Fill</div>
              <div>
                {{ LastFillDate }}
              </div>
              <div>
                <span>
                  {{ LastFill.pounds }}
                </span>
                <span> lbs </span>
              </div>
            </div>
            <q-separator />

            <div class="q-pa-md">
              <div class="q-px-sm q-pb-sm">
                <div class="text-center text-subtitle2">Enter Lbs</div>
                <div
                  class="row items-center text-h6"
                  style="
                    height: 50px;
                    padding: 0px 20px;
                    border: 1px solid black;
                    border-radius: 30px;
                  "
                >
                  {{ PoundsDisplay }}
                </div>
                <div
                  v-if="poundsErr"
                  class="text-accent"
                  style="padding: 0px 22px"
                >
                  Please enter a value
                </div>
              </div>

              <div class="row">
                <div class="col-4" v-for="n in 9" :key="n">
                  <div class="q-pa-sm">
                    <q-btn
                      rounded
                      color="primary"
                      class="full-width"
                      padding="10px"
                      @click="addToPounds(n)"
                    >
                      <div class="text-h6">
                        {{ n }}
                      </div>
                    </q-btn>
                  </div>
                </div>

                <div class="col-8 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="addToPounds(0)"
                  >
                    <div class="text-h6">0</div>
                  </q-btn>
                </div>

                <div class="col-4 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="backspace"
                  >
                    <div style="padding: 2px 4px 2px 0">
                      <q-icon name="backspace" size="28px" />
                    </div>
                  </q-btn>
                </div>
              </div>
            </div>
            <q-separator />

            <div class="row q-py-lg q-px-lg">
              <div class="col-6 q-pr-sm">
                <q-btn
                  rounded
                  label="Cancel"
                  color="grey-4"
                  text-color="black"
                  size="md"
                  padding="10px"
                  class="full-width"
                  v-close-popup
                />
              </div>
              <div class="col-6 q-pl-sm">
                <q-btn
                  rounded
                  label="Fill Feeder"
                  color="secondary"
                  text-color="black"
                  size="md"
                  class="full-width"
                  padding="10px"
                  type="submit"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>

    <!-- Overview Dialog -->
    <q-dialog position="top" full-height v-model="dialogOverview">
      <q-card style="width: 450px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5">Overview</div>
          <q-btn
            unelevated
            icon="clear"
            color="primary"
            @click="dialogOverview = false"
          />
        </div>
        <q-separator style="padding-top 1px" />

        <div
          class="scroll q-px-md q-pt-md"
          style="max-height: calc(100vh - 100px)"
        >
          <div class="text-subtitle1 q-pb-sm">
            <div class="row justify-between">
              <div>Remaining Feed</div>
              <div>{{ RemainingFeed.toLocaleString() }}</div>
            </div>
            <q-separator />
          </div>

          <div class="text-subtitle1 q-py-xs">
            <div class="row justify-between">
              <div>Last 10 Feed Day Avg</div>
              <div>{{ LastTenFeedAvg.toLocaleString() }}</div>
            </div>
            <q-separator />
          </div>

          <div class="text-subtitle1 q-py-sm">
            <div class="row justify-between">
              <div>Feed Days</div>
              <div>
                {{ FeedDays.toFixed(1) }}
              </div>
            </div>
            <q-separator />
          </div>

          <div v-if="displayTFA" class="text-subtitle1 q-py-sm">
            <div class="row justify-between">
              <div>Farm TFA</div>
              <div>{{ numberWithCommas(FarmTFA) }}</div>
            </div>
            <q-separator />
          </div>

          <div class="row q-pt-md">
            <div v-for="bin in Bins" :key="bin.id" class="col-12 row">
              <BinOverview
                :bin="bin"
                :buttons="false"
                :fillBtn="true"
                @fillBin="fillBin"
              />
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- Feed Settings Dialog -->
    <q-dialog position="top" full-height v-model="dialogFeedSettings">
      <q-card style="width: 450px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5">Feed Settings</div>
          <q-btn
            unelevated
            icon="clear"
            color="primary"
            @click="dialogFeedSettings = false"
          />
        </div>
        <q-separator />

        <div class="scroll" :style="`max-height: ${useableHeight - 20}px`">
          <div class="text-center text-bold text-primary bg-grey-1 q-py-xs">
            Device Settings
          </div>
          <q-separator />

          <div class="q-px-md">
            <div class="q-pa-sm text-subtitle1 text-bold text-center">
              <div>Feeder</div>
            </div>
            <q-separator />

            <div class="q-py-sm">
              <div
                v-for="feeder in FeederOptions"
                :key="feeder.value"
                class="q-px-md q-py-sm"
              >
                <q-radio
                  dense
                  v-model="selectedFeeder"
                  :val="feeder.value"
                  :label="feeder.label"
                  emit-value
                  color="primary"
                  size="lg"
                  @input="changeFeeder"
                  padding="10px md"
                />
              </div>
            </div>
            <q-separator />

            <div class="q-pa-sm text-subtitle1 text-bold text-center">
              <div>Bin Indicators</div>
            </div>
            <q-separator />

            <div class="row q-pt-md">
              <div
                v-for="(bin, index) in Bins"
                :key="bin.id"
                class="col-12 row q-px-md q-pb-md"
              >
                <q-toggle
                  dense
                  v-model="binDisplayToggles[index]"
                  color="primary"
                  :label="decoder(bin.name)"
                  size="lg"
                  :disable="!bin.in_service"
                  @input="toggleBinDisplay(bin, index)"
                />
              </div>
            </div>
            <q-separator />

            <div class="q-pa-sm text-subtitle1 text-bold text-center">
              <div>Chart Options</div>
            </div>
            <q-separator />

            <div class="q-px-md q-pb-sm q-pt-md">
              <q-toggle
                dense
                v-model="feedingsAllDisplay"
                label="Display All Feedings"
                color="primary"
                size="lg"
                padding="10px md"
              />
            </div>
            <div class="q-px-md q-py-sm">
              <q-toggle
                dense
                v-model="feedingsMorningDisplay"
                label="Display Morning Feedings"
                color="primary"
                size="lg"
                padding="10px md"
              />
            </div>
            <div class="q-px-md q-pt-sm q-pb-md">
              <q-toggle
                dense
                v-model="feedingsEveningDisplay"
                label="Display Evening Feedings"
                color="primary"
                size="lg"
                padding="10px md"
              />
            </div>
          </div>

          <div v-if="IsAdmin || IsAccountOwner">
            <q-separator />

            <div class="text-center text-bold text-primary bg-grey-1 q-py-xs">
              Farm Feed Settings
            </div>
            <q-separator />

            <div class="q-px-md">
              <div class="q-pa-sm text-subtitle1 text-bold text-center">
                <div>Feed Options</div>
              </div>
              <q-separator />
              <div class="q-py-sm">
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="feedDividerOption"
                    :val="0"
                    label="Use full days for Last & Today"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="feedDividerOption"
                    :val="1"
                    label="Use morning and evening for Last & Current"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
              </div>
              <q-separator />

              <div class="q-pa-sm text-subtitle1 text-bold text-center">
                <div>Morning / Evening Divide Time</div>
              </div>
              <q-separator />

              <div class="q-py-sm">
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="divideTime"
                    :val="11"
                    label="11:00 AM"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="divideTime"
                    :val="12"
                    label="12:00 PM"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="divideTime"
                    :val="1"
                    label="1:00 PM"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
                <div class="q-px-md q-py-sm">
                  <q-radio
                    dense
                    v-model="divideTime"
                    :val="2"
                    label="2:00 PM"
                    color="primary"
                    size="lg"
                    padding="10px md"
                    @input="setFarmFeedSettings"
                  />
                </div>
              </div>
              <q-separator />

              <div class="q-pa-sm text-subtitle1 text-bold text-center">
                <div>Display TFA</div>
              </div>
              <q-separator />

              <div class="q-px-md q-pt-md q-pb-lg">
                <q-toggle
                  dense
                  v-model="displayTFA"
                  label="Display TFA"
                  color="primary"
                  size="lg"
                  padding="10px md"
                  @input="setFarmFeedSettings"
                />
              </div>
            </div>
            <q-separator />

            <div class="text-center text-bold text-primary bg-grey-1 q-py-xs">
              Farm Bin Settings
            </div>
            <q-separator />

            <div class="q-px-md">
              <div class="q-pa-sm text-center">
                <div class="text-subtitle1 text-bold">
                  Default Bin Selection
                </div>
                <div>(On Feeder Fillups)</div>
              </div>
              <q-separator />

              <div class="q-px-md q-pt-md q-pb-lg">
                <q-toggle
                  dense
                  v-model="defaultBinSelection"
                  label="Default Bin Selection"
                  color="primary"
                  size="lg"
                  padding="10px md"
                  @input="setFarmFeedSettings"
                />
              </div>
              <q-separator />

              <div class="q-pa-sm text-subtitle1 text-bold text-center">
                <div>In Service</div>
              </div>
              <q-separator />

              <div class="row q-pt-md">
                <div
                  v-for="(bin, index) in Bins"
                  :key="bin.id + 'in_service'"
                  class="col-12 row q-px-md q-pb-md"
                >
                  <q-toggle
                    dense
                    v-model="inServiceBins[index]"
                    color="primary"
                    :label="decoder(bin.name)"
                    size="lg"
                    @input="toggleInServiceBin(bin, index)"
                  />
                </div>
              </div>
              <q-separator />

              <div class="q-pa-sm text-center">
                <div class="text-subtitle1 text-bold">Bin Sort Order</div>
                <div>(drag & drop)</div>
              </div>
              <q-separator />

              <div class="q-pt-md q-pb-lg">
                <draggable
                  v-model="binSortList"
                  group="bins"
                  @end="setBinSortList"
                >
                  <div
                    v-for="(bin, index) in binSortList"
                    :key="bin.id + 'binSortList'"
                    class="q-pb-sm"
                  >
                    <q-card flat class="bg-grey-2 q-py-xs q-px-md">
                      <div style="cursor: move" class="row justify-between">
                        <div>
                          {{ decoder(bin.name) }}
                        </div>
                        <div>
                          {{ index + 1 }}
                        </div>
                      </div>
                    </q-card>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import BinOverview from '@/components/feed/BinOverview.vue';
import FeederOverview from '@/components/feed/FeederOverview.vue';
import FeedPondRow from '@/components/feed/FeedPondRow.vue';
import FillBin from '@/components/feed/dialogs/FillBin.vue';
import FeedChart from '@/components/feed/FeedChart.vue';

import { date } from 'quasar';
import draggable from 'vuedraggable';
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth,
  smartCompare
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import AuthUtils from '@/lib/auth-utils';
import {
  getCurrentByFeeding,
  getCurrentByDay,
  getLastByFeeding,
  getLastByDay,
  getChartData,
  getChartTotals
} from '@/lib/feedingHelpers';

export default {
  name: 'FeedEntries',
  components: {
    BinOverview,
    FeederOverview,
    FeedPondRow,
    FillBin,
    FeedChart,
    draggable
  },
  data() {
    return {
      binDisplayToggles: [],
      binIndicators: [],
      binSortList: [],
      decoder,
      defaultBinSelection: true,
      dialogFeedSettings: false,
      dialogFillBin: false,
      dialogFillFeeder: false,
      dialogOverview: false,
      displayTFA: true,
      divideTime: 12,
      feederPlaceholder: {
        balance: 0,
        capacity: 0,
        name: ''
      },
      feedingsAllDisplay: true,
      feedingsEveningDisplay: true,
      feedingsMorningDisplay: true,
      inServiceBins: [],
      isDateValid,
      isNumValid,
      feedDividerOption: 0,
      lastFeedingDates: {},
      numberWithCommas,
      poundsErr: false,
      resizeWatcher: false,
      selectedFeeder: null,
      selectedBin: {},
      displayCharts: false,
      displayOverfed: false,
      transaction: createTransactionObj(),
      useableHeight: 500
    };
  },
  mounted() {
    this.setFeeder();
    this.onResize();
    this.setFeedSettingsInitial();
    this.setBinDisplayToggles();
    this.setInServiceBins();
    this.binSortList = cloneObj(this.Bins);
  },
  methods: {
    addToPounds(n) {
      if (parseInt(this.transaction.pounds) > 99999) {
        return;
      }

      if (this.transaction.pounds.length > 0 && n === 0) {
        this.transaction.pounds += n.toString();
        return;
      }

      this.transaction.pounds = parseInt(
        this.transaction.pounds + n
      ).toString();
    },
    backspace() {
      if (this.transaction.pounds.length === 1) {
        this.transaction.pounds = '0';
        return;
      }

      this.transaction.pounds = this.transaction.pounds.slice(0, -1);
    },
    changeFeeder() {
      const feeder = this.FeederOptions.find(
        (x) => x.value === this.selectedFeeder
      );

      localStorage.setItem('feederSelected', feeder.value);
    },
    fillBin(bin) {
      this.transaction.pounds = '0';
      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');
      this.selectedBin = this.Bins.find((x) => x.grain_id === bin.grain_id);

      this.dialogFillBin = true;
    },
    fillFeeder() {
      this.transaction.pounds = '0';
      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');

      if (store.state.farm.settings.default_bin_selection) {
        this.transaction.from_account =
          this.FeederSelected?.fillUps[0]?.from_account ?? null;
      }

      this.dialogFillFeeder = true;
    },
    fillFeederSubmit() {
      if (this.transaction.pounds === '0') {
        this.poundsErr = true;
        return;
      }
      this.poundsErr = false;

      const transaction = cloneObj(this.transaction);
      transaction.pounds = parseFloat(transaction.pounds);

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      transaction.id = 0;

      transaction.to_account = this.FeederSelected.grain_id;

      transaction.storeInfo.action = 'createFeederEntry';
      transaction.storeInfo.feederBal =
        parseFloat(this.FeederSelected.balance) + transaction.pounds;
      transaction.storeInfo.category = 'feederEntries';
      transaction.storeInfo.feederId = this.FeederSelected.id;
      transaction.storeInfo.feederGrainId = this.FeederSelected.grain_id;

      guidMatchHelper(transaction, 'create');

      const bin = this.BinOptions.find(
        (x) => x.value === transaction.from_account
      );

      transaction.bin_name = bin.name;

      transaction.feed_type = bin.feedTypeId;
      transaction.feed_type_name = bin.feedTypeName;

      // Add details to update bin info
      transaction.storeInfo.binId = bin.id;
      transaction.storeInfo.binGrainId = bin.value;
      transaction.storeInfo.binBal =
        parseFloat(bin.balance) - transaction.pounds;
      transaction.feeder_name = this.FeederSelected.label;
      transaction.storeInfo.reconcile = false;

      store.dispatch('publish', transaction);

      this.dialogFillFeeder = false;

      this.$q.notify({
        message: 'Feeder Entry Submitted',
        icon: 'check',
        color: 'primary'
      });

      this.transaction = createTransactionObj();
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.useableHeight = height - 103;

      this.resizeWatcher = !this.resizeWatcher;
    },
    setBinDisplayToggles() {
      const binDisplayToggles =
        JSON.parse(localStorage.getItem('binDisplayToggles')) ?? [];

      this.binDisplayToggles = [];
      for (const bin of this.Bins) {
        const binToggle = binDisplayToggles.find((x) => x.binId === bin.id);

        if (!binToggle) {
          this.binDisplayToggles.push(false);

          binDisplayToggles.push({
            binId: bin.id,
            display: false
          });
        } else {
          this.binDisplayToggles.push(binToggle.display);
        }
      }

      localStorage.setItem(
        'binDisplayToggles',
        JSON.stringify(binDisplayToggles)
      );
    },
    async setBinSortList() {
      // loop through this.Bins and update the sort order
      for (let i = 0; i < this.binSortList.length; i++) {
        const bin = cloneObj(this.binSortList[i]);

        if (i !== bin.sort_order) {
          bin.storeInfo = {
            farmId: store.state.farm.id
          };
          bin.sort_order = i;

          store.dispatch('updateBin', {
            bin
          });
        }
      }
    },
    setInServiceBins() {
      this.inServiceBins = [];
      for (const bin of this.Bins) {
        this.inServiceBins.push(bin.in_service);
      }
    },
    setChartOptions() {
      const feedSettings = {
        feedingsAllDisplay: this.feedingsAllDisplay,
        feedingsMorningDisplay: this.feedingsMorningDisplay,
        feedingsEveningDisplay: this.feedingsEveningDisplay
      };

      localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
    },
    setFarmBinSettings() {
      if (this.IsAdmin || this.IsAccountOwner) {
        const farmSettings = cloneObj(store.state.farm.settings);
        farmSettings.default_bin_selection = this.defaultBinSelection;

        store.dispatch('updateFarmSettings', { farmSettings });
      }
    },
    setFarmFeedSettings() {
      if (this.IsAdmin || this.IsAccountOwner) {
        const farmSettings = cloneObj(store.state.farm.settings);
        farmSettings.feed_divider_option = parseInt(this.feedDividerOption);
        farmSettings.feed_divider_time = parseInt(this.divideTime);
        farmSettings.display_tfa = this.displayTFA;
        farmSettings.default_bin_selection = this.defaultBinSelection;

        store.dispatch('updateFarmSettings', { farmSettings });
      }
    },
    setFeedSettingsInitial() {
      let feedSettings = localStorage.getItem('feedSettings');

      if (!feedSettings) {
        feedSettings = {
          feedingsAllDisplay: this.feedingsAllDisplay,
          feedingsMorningDisplay: this.feedingsMorningDisplay,
          feedingsEveningDisplay: this.feedingsEveningDisplay,
          feedDividerOption: this.feedDividerOption
        };

        localStorage.setItem('feedSettings', JSON.stringify(feedSettings));
        return;
      }

      feedSettings = JSON.parse(feedSettings);

      this.feedingsAllDisplay = feedSettings.feedingsAllDisplay;
      this.feedingsMorningDisplay = feedSettings.feedingsMorningDisplay;
      this.feedingsEveningDisplay = feedSettings.feedingsEveningDisplay;
      this.feedDividerOption =
        store.state.farm?.settings?.feed_divider_option ?? 0;
      this.divideTime = store.state.farm?.settings?.feed_divider_time ?? 12;
      this.displayTFA = store.state.farm?.settings?.display_tfa ?? true;
      this.defaultBinSelection =
        store.state.farm?.settings?.default_bin_selection ?? true;
    },
    setFeeder() {
      const deviceFeeder = localStorage.getItem('feederSelected');

      if (!deviceFeeder) {
        this.selectedFeeder = this.FeederOptions[0].value;
        return;
      }

      const feeder = this.FeederOptions.find(
        (x) => x.value === parseInt(deviceFeeder)
      );

      if (!feeder) {
        this.selectedFeeder =
          this.FeederOptions[0]?.value ?? this.feederPlaceholder;
        return;
      }

      this.selectedFeeder = feeder.value;
    },
    toggleBinDisplay(toggledBin, toggledIndex) {
      const binDisplayToggles =
        JSON.parse(localStorage.getItem('binDisplayToggles')) ?? [];

      const index = binDisplayToggles.findIndex(
        (x) => x.binId === toggledBin.id
      );

      const display = this.binDisplayToggles[toggledIndex];

      if (index === -1) {
        binDisplayToggles.push({ binId: toggledBin.id, display });
      } else {
        binDisplayToggles[index].display = display;
      }

      localStorage.setItem(
        'binDisplayToggles',
        JSON.stringify(binDisplayToggles)
      );

      this.setBinDisplayToggles();
    },
    toggleInServiceBin(toggledBin, toggledIndex) {
      const bin = cloneObj(toggledBin);

      bin.in_service = this.inServiceBins[toggledIndex];

      if (!bin.in_service) {
        this.binDisplayToggles[toggledIndex] = false;
        this.toggleBinDisplay(bin, toggledIndex);
      }

      bin.storeInfo = {
        farmId: store.state.farm.id
      };

      store.dispatch('updateBin', {
        bin
      });
    },
    setLastFeedingDate(pondId, feedingDate) {
      this.lastFeedingDates[pondId] = feedingDate;

      // Have to clone the object to trigger the reactivity :(
      this.lastFeedingDates = cloneObj(this.lastFeedingDates);
    }
  },
  computed: {
    BinIndicators() {
      const indicators = [];
      for (let i = 0; i < this.Bins.length; i++) {
        if (this.binDisplayToggles[i]) {
          indicators.push(this.Bins[i]);
        }
      }

      return indicators;
    },
    BinOptions() {
      const options = [];
      for (const bin of this.Bins) {
        if (!bin.in_service) {
          continue;
        }

        // get the feed type of the bin - which is the feed type of the latest delivery
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of bin.deliveries) {
          if (entry.mill_name) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: bin.id,
          balance: bin.balance,
          name: bin.name,
          label: bin.name + ' -- ' + feedTypeName,
          value: bin.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    Bins() {
      const bins = cloneObj(store.state.farm.bins)
        .filter((x) => !x.archived)
        .map((x) => ({ ...x, binLevel: getPercentage(x.balance, x.capacity) }))
        .sort((a, b) => smartCompare(a.name, b.name))
        .sort((a, b) => a.sort_order - b.sort_order);

      return bins;
    },
    ChartTotals() {
      return getChartTotals(
        this.Ponds,
        this.feedingsAllDisplay,
        this.feedingsMorningDisplay,
        this.feedingsEveningDisplay
      );
    },
    CurrentLabel() {
      return this.feedDividerOption === 0 ? 'Today' : 'Current';
    },
    CurrentTotal() {
      let total = 0;
      for (const pond of this.Ponds) {
        if (this.feedDividerOption === 0) {
          total += getCurrentByDay(pond.feedings).pounds;
        } else {
          total += getCurrentByFeeding(pond.feedings).pounds;
        }
      }

      return numberWithCommas(total);
    },
    FarmTFA() {
      let tfa = 0;
      for (const pond of this.Ponds) {
        tfa += pond.growth?.targetFeedAmount ?? 0;
      }
      return tfa;
    },
    FeedDays() {
      return this.LastTenFeedAvg ? this.RemainingFeed / this.LastTenFeedAvg : 0;
    },
    FeederLevelPercent() {
      return getPercentage(
        this.FeederSelected.balance,
        this.FeederSelected.capacity
      );
    },
    FeederSelected() {
      return (
        this.FeederOptions.find((x) => x.value === this.selectedFeeder) ||
        this.feederPlaceholder
      );
    },
    FeederOptions() {
      const options = [];
      for (const feeder of store.state.farm.feeders) {
        // get the feed type of the feeder
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of feeder.fillUps) {
          if (entry.feed_type) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: feeder.id,
          balance: feeder.balance,
          label: feeder.name,
          value: feeder.grain_id,
          feedTypeId,
          feedTypeName,
          ...feeder
        });
      }

      return options;
    },
    IsAccountOwner() {
      const userRole = AuthUtils.roleDecoder(this.$store.getters.userFarmRole);
      return userRole === 'Account owner';
    },
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    },
    LastFill() {
      return this.FeederSelected?.fillUps?.[0] ?? { date: null, amount: 0 };
    },
    LastFillDate() {
      return this.LastFill.date
        ? date.formatDate(this.LastFill.date * 1000, 'ddd, MMM D')
        : '---';
    },
    LastTenFeedAvg() {
      let totalFeed = 0;
      const days = new Set();
      const daysMap = new Map();
      for (const pond of store.state.farm.ponds) {
        for (const entry of pond.feedings) {
          const feedDayTS = +new Date(
            date.startOfDate(new Date(entry.date * 1000), 'day')
          );

          if (daysMap.has(feedDayTS)) {
            daysMap.set(feedDayTS, daysMap.get(feedDayTS) + entry.pounds);
          } else {
            daysMap.set(feedDayTS, entry.pounds);
          }
        }
      }

      const daysSortedArr = Array.from(daysMap.keys())
        .sort()
        .reverse()
        .slice(0, 10);

      for (const day of daysSortedArr) {
        totalFeed += daysMap.get(day);
      }

      return Math.round(totalFeed / 10);
    },
    LastTotal() {
      let total = 0;
      for (const pond of this.Ponds) {
        let lastFeedingDate = this.lastFeedingDates[pond.id] * 1000;
        lastFeedingDate = +new Date(
          date.startOfDate(new Date(lastFeedingDate), 'day')
        );

        let feedingSkipped = false;
        for (const feedingDate of Object.values(this.lastFeedingDates)) {
          let feedingDateStart = feedingDate * 1000;
          feedingDateStart = +new Date(
            date.startOfDate(new Date(feedingDateStart), 'day')
          );

          if (feedingDateStart > lastFeedingDate) {
            feedingSkipped = true;
            break;
          }
        }

        const lastFeeding =
          this.feedDividerOption === 0
            ? getLastByFeeding(pond.feedings)
            : getLastByDay(pond.feedings);

        if (!feedingSkipped) {
          total += lastFeeding.pounds;
        }
      }

      return numberWithCommas(total);
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name))
        .map((x) => {
          const chartData = getChartData(
            x,
            this.feedingsAllDisplay,
            this.feedingsMorningDisplay,
            this.feedingsEveningDisplay
          );
          return { ...x, chartData };
        });
    },
    PoundsDisplay() {
      if (!this.transaction.pounds) {
        return '0';
      }

      return numberWithCommas(parseFloat(this.transaction.pounds));
    },
    RemainingFeed() {
      let feed = 0;
      for (const bin of this.$store.state.farm.bins) {
        const bal = parseFloat(bin.balance);
        feed += bal > 0 ? bal : 0;
      }

      for (const feeder of this.$store.state.farm.feeders) {
        const bal = parseFloat(feeder.balance);
        feed += bal > 0 ? bal : 0;
      }

      return feed;
    },
    ScrollbarWidth() {
      return getScrollbarWidth();
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  },
  watch: {
    Bins() {
      this.setBinDisplayToggles();
      this.setInServiceBins();
      this.binSortList = cloneObj(this.Bins);
    },
    FeederOptions() {
      this.setFeeder();
    }
  }
};
</script>

<style></style>
