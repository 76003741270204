const dataEntryPages = [
  {
    icon: 'set_meal',
    name: 'Feed',
    path: '/feed'
  },
  {
    icon: 'medication_liquid',
    name: 'Treat',
    path: '/treat'
  },
  {
    icon: 'bloodtype',
    name: 'Oxygen',
    path: '/oxygen'
  },
  {
    icon: 'local_dining',
    name: 'Flavor',
    path: '/flavor'
  }
];

const dashboardPages = [
  {
    icon: 'dashboard',
    name: 'Dashboard',
    path: '/feed-dashboard'
  },
  {
    icon: 'water',
    name: 'Ponds',
    path: '/ponds'
  },
  {
    icon: 'event',
    name: 'Calendar',
    path: '/calendar'
  },
  {
    icon: 'analytics',
    name: 'Reports',
    path: '/reports'
  },
  {
    icon: 'collections',
    name: 'Images',
    path: '/farm-images'
  },
  {
    icon: 'settings',
    name: 'Settings',
    path: '/settings'
  }
];

export { dataEntryPages, dashboardPages };
