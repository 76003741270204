<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img src="/images/bins.png" alt="Bins" width="100%" height="100%" />
      </div>
      <div class="q-pt-md">
        <q-toggle v-model="displayArchived" label="Display Archived" />
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div class="col q-pl-sm">Capacity</div>
      <div class="col q-pl-sm">Tons</div>
      <div class="col q-pl-sm">In Service</div>
      <div class="col q-pl-sm">Location</div>
      <div class="col-auto" :style="`width: ${$q.screen.xs ? '88' : '112'}px`">
        <div class="row justify-end q-pr-sm">
          <q-btn
            unelevated
            label="Sort"
            icon="swap_vert"
            size="sm"
            color="primary"
            class="gt-xs"
            style="width: 96px"
            @click="dialogSortOrder = true"
          />
          <q-btn
            unelevated
            icon="swap_vert"
            size="sm"
            color="primary"
            class="lt-sm"
            @click="dialogSortOrder = true"
          />
        </div>
      </div>
    </div>

    <q-separator color="black" />

    <div v-for="bin in Bins" :key="bin.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ decoder(bin.name) }}</div>
        <div class="col q-pl-sm">
          {{ numberWithCommas(bin.capacity) }}
        </div>
        <div class="col q-pl-sm">
          {{ (bin.capacity / 2000).toFixed(1) }}
        </div>
        <div class="col">
          <q-icon
            :name="bin.in_service ? 'check' : 'close'"
            :color="bin.in_service ? 'primary' : 'accent'"
            size="sm"
          />
        </div>
        <div class="col q-pl-sm">
          {{ locationDecoder(bin.location_id) }}
        </div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editBin(bin)"
          >
          </q-btn>
          <q-btn
            v-if="!bin.archived && bin.hasEntries"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveBin(bin, true)"
          />
          <q-btn
            v-if="bin.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveBin(bin, false)"
          />
          <q-btn
            v-if="!bin.archived && !bin.hasEntries"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteBin(bin)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Bin Entry -->
    <q-dialog v-model="dialogBinEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">{{ binObj.name }}</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="binObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model="binObj.capacity"
            label="Capacity"
            type="number"
            min="0"
            step="1"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => !!value || 'Please enter capacity']"
          />
          <q-select
            outlined
            v-if="locations.length > 0"
            v-model="binObj.location_id"
            :options="LocationOptions"
            emit-value
            map-options
            label="Location"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Please select a location']"
          />
          <q-separator />
          <div class="q-py-sm">
            <q-toggle v-model="binObj.in_service" dense label="In Service" />
          </div>
          <q-separator />

          <div class="row justify-end q-pt-md">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Dialog Sort Order -->
    <q-dialog v-model="dialogSortOrder" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-px-md q-py-sm">
          <div class="col">
            <div class="col text-center text-subtitle1 text-bold">
              Bin Sort Order
            </div>
            <div class="col text-center text-caption">(drag & drop)</div>
          </div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div class="q-px-md q-pt-md q-pb-sm">
          <draggable v-model="binSortList" group="bins" @end="setBinSortList">
            <div
              v-for="(bin, index) in binSortList"
              :key="bin.id + 'binSortList'"
              class="q-pb-sm"
            >
              <q-card flat class="bg-grey-2 q-py-xs q-px-md">
                <div style="cursor: move" class="row justify-between">
                  <div>
                    {{ decoder(bin.name) }}
                  </div>
                  <div>
                    {{ index + 1 }}
                  </div>
                </div>
              </q-card>
            </div>
          </draggable>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {
  decoder,
  numberWithCommas,
  cloneObj,
  upperCaseFirst
} from '@/lib/helpers';
import store from '@/store';
import draggable from 'vuedraggable';

const newBin = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    grain_id: null,
    name: '',
    capacity: null,
    location_id: null,
    sort_order: 0,
    in_service: true,
    archived: false,
    transactions: 0,
    withdrawals: [],
    deliveries: [],
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Bins',
  components: {
    draggable
  },
  data() {
    return {
      binObj: newBin(),
      binSortList: [],
      decoder,
      dialogBinEntry: false,
      dialogSortOrder: false,
      displayArchived: false,
      numberWithCommas,
      store
    };
  },
  mounted() {
    this.binSortList = cloneObj(this.Bins);
  },
  methods: {
    add() {
      this.binObj = newBin();
      this.binObj.sort_order = this.Bins.length;

      this.dialogBinEntry = true;
    },
    archiveBin(bin, value) {
      const binCopy = cloneObj(bin);
      binCopy.archived = value;

      binCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Bin',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this bin?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveBinConfirmed(binCopy);
        });
    },
    archiveBinConfirmed(bin) {
      store
        .dispatch('updateBin', {
          bin
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteBin(bin) {
      bin.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Bin',
          message: 'Are you sure you want to delete this bin?',
          ok: {
            color: 'negative',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'Cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteBinConfirmed(bin);
        });
    },
    deleteBinConfirmed(bin) {
      store
        .dispatch('deleteBin', {
          bin
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editBin(bin) {
      this.binObj = cloneObj(bin);
      this.binObj.name = decoder(this.binObj.name);
      this.dialogBinEntry = true;
    },
    locationDecoder(id) {
      if (id === null) {
        return '';
      }
      const farm = this.$store.getters.selectedFarm;
      if (!farm) {
        return '';
      }
      const location = farm.locations.find((element) => element.id === id);
      return location.name;
    },
    onSubmit() {
      let dispatch;
      if (this.binObj.id === 0) {
        dispatch = 'createBin';
      } else {
        dispatch = 'updateBin';
      }

      this.binObj.name = upperCaseFirst(this.binObj.name);

      this.binObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          bin: this.binObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogBinEntry = false;
    },
    async setBinSortList() {
      // loop through this.Bins and update the sort order
      for (let i = 0; i < this.binSortList.length; i++) {
        const bin = cloneObj(this.binSortList[i]);

        if (i !== bin.sort_order) {
          bin.storeInfo = {
            farmId: store.state.farm.id
          };
          bin.sort_order = i;

          store.dispatch('updateBin', {
            bin
          });
        }
      }
    }
  },
  computed: {
    LocationOptions() {
      const locations = [];
      for (let i = 0; i < this.locations.length; i++) {
        for (const location of this.locations) {
          locations.push({
            label: decoder(location.name),
            value: location.id,
            name: location.name
          });
        }
      }

      return locations;
    },
    locations() {
      return this.$store.getters.selectedFarm
        ? this.$store.getters.selectedFarm.locations
        : [];
    },
    Bins() {
      let bins = cloneObj(this.$store.state.farm.bins);

      if (!this.displayArchived) {
        bins = bins.filter((element) => !element.archived);
      }

      for (const bin of bins) {
        bin.hasEntries =
          bin.deliveries.length > 0 || bin.withdrawals.length > 0;
      }

      return bins
        .sort(function (a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        })
        .sort((a, b) => a.sort_order - b.sort_order);
    }
  },
  watch: {
    Bins() {
      this.binSortList = cloneObj(this.Bins);
    }
  }
};
</script>

<style scoped></style>
