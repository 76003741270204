<template>
  <div>
    <div class="row items-center q-py-xs q-px-sm">
      <div class="col col-sm-auto" style="width: 90px">Date</div>
      <div class="col col-sm-auto" style="width: 150px">Processor</div>
      <div class="col col-sm-auto" style="width: 100px">Flavor</div>
      <div class="col col-sm-auto" style="width: 100px">Severity</div>
      <div class="col">Memo</div>
    </div>
    <q-separator />

    <div
      :style="`height: ${store.state.pageHeight - 133}px`"
      class="scroll q-pb-md"
    >
      <div v-for="pond in Flavors" :key="pond.id">
        <div class="bg-grey-2 text-subtitle1 text-bold q-px-sm">
          {{ pond.name }}
        </div>
        <div>
          <q-separator />
          <div
            v-for="(flavor, index) in pond.flavors"
            :key="flavor.id + 'flavor'"
          >
            <div class="row q-px-sm">
              <div class="col col-sm-auto text-caption" style="width: 90px">
                {{ flavor.date }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ flavor.processor }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 100px">
                {{ flavor.name }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 100px">
                {{ flavor.severity }}
              </div>
              <div class="col text-caption">{{ flavor.memo }}</div>
            </div>
            <q-separator v-if="index < pond.flavors.length - 1" />
          </div>
        </div>

        <q-separator />
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'TreatmentReports',
  props: {
    dates: {
      type: Object,
      required: true
    },
    pondFilter: {
      type: Array,
      required: true
    }
  },
  data() {
    return { store };
  },
  computed: {
    Flavors() {
      const start = parseInt(date.formatDate(this.dates.from, 'X'));
      let end = date.endOfDate(this.dates.to, 'day');
      end = parseInt(date.formatDate(end, 'X'));

      const ponds = [];
      for (const pond of store.state.farm.ponds) {
        if (!this.pondFilter.includes(pond.id)) {
          continue;
        }

        const pondObj = {
          name: pond.name,
          id: pond.id,
          flavors: []
        };

        for (const flavor of pond.flavorHistory) {
          if (flavor.date < start || flavor.date > end) {
            continue;
          }

          const flavorObj = {
            date: date.formatDate(flavor.date * 1000, 'MM/DD/YY'),
            name: flavor.flavor_name,
            id: flavor.id,
            processor: flavor.processor,
            severity: flavor.severity,
            memo: flavor.memo
          };

          pondObj.flavors.push(flavorObj);
        }

        ponds.push(pondObj);
      }

      return ponds;
    }
  }
};
</script>

<style scoped></style>
