<template>
  <div>
    <div class="row items-center justify-between q-pa-sm">
      <div>
        <q-btn
          unelevated
          label="Filter"
          icon="filter_list"
          color="grey-3"
          text-color="black"
          size="md"
          class="gt-xs q-mx-xs"
          @click="dialogFilter = true"
        />
        <q-btn
          unelevated
          icon="filter_list"
          color="grey-3"
          text-color="black"
          size="md"
          class="lt-sm q-mx-xs"
          @click="dialogFilter = true"
        />
      </div>

      <!-- <div class="gt-xs"> -->
      <!--   <q-btn -->
      <!--     unelevated -->
      <!--     label="Treatments" -->
      <!--     :outline="currentView === 'Treatments'" -->
      <!--     size="md" -->
      <!--     class="q-mx-xs" -->
      <!--     @click="currentView = 'Treatments'" -->
      <!--   /> -->
      <!--   <q-btn -->
      <!--     unelevated -->
      <!--     :outline="currentView === 'Flavors'" -->
      <!--     label="Flavors" -->
      <!--     size="md" -->
      <!--     class="q-mx-xs" -->
      <!--     @click="currentView = 'Flavors'" -->
      <!--   /> -->
      <!--   <q-btn -->
      <!--     unelevated -->
      <!--     :outline="currentView === 'Oxygen'" -->
      <!--     label="Oxygen" -->
      <!--     size="md" -->
      <!--     class="q-mx-xs" -->
      <!--     @click="currentView = 'Oxygen'" -->
      <!--   /> -->
      <!--   <q-btn -->
      <!--     unelevated -->
      <!--     label="Feed Detail" -->
      <!--     :outline="currentView === 'Feed Detail'" -->
      <!--     size="md" -->
      <!--     class="q-mx-xs" -->
      <!--     @click="(currentView = 'Feed Detail'), setLast7Days()" -->
      <!--   /> -->
      <!-- </div> -->

      <div class="text-subtitle1 text-bold">
        {{ currentView }}
      </div>

      <div>
        <q-btn-dropdown
          unelevated
          color="grey-3"
          text-color="black"
          label="Reports"
          anchor="bottom right"
          self="top right"
          :menu-offset="[0, 0]"
        >
          <!-- <q-list style="min-width: 145px"> -->
          <q-list>
            <q-item clickable v-close-popup @click="currentView = 'Treatments'">
              <q-item-section>Treatments</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="currentView = 'Flavors'">
              <q-item-section>Flavors</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="currentView = 'Oxygen'">
              <q-item-section>Oxygen</q-item-section>
            </q-item>
            <q-item
              clickable
              v-close-popup
              @click="(currentView = 'Feed Detail'), setLast7Days()"
            >
              <q-item-section>Feed Detail</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
    <q-separator />

    <Treatments
      v-if="currentView === 'Treatments'"
      :pondFilter="PondFilter"
      :treatmentFilter="TreatmentFilter"
      :dates="datesSelectedCopy"
    />
    <Flavors
      v-if="currentView === 'Flavors'"
      :pondFilter="PondFilter"
      :dates="datesSelectedCopy"
    />
    <Oxygen
      v-if="currentView === 'Oxygen'"
      :pondFilter="PondFilter"
      :dates="datesSelectedCopy"
    />
    <FeedDetail
      v-if="currentView === 'Feed Detail'"
      :pondFilter="PondFilter"
      :dates="datesSelectedCopy"
    />

    <!-- Filter Dialog -->
    <q-dialog v-model="dialogFilter" full-height position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div style="width: 56px"></div>
          <div class="col text-center text-h5">Filter</div>
          <div>
            <q-btn
              unelevated
              icon="close"
              color="grey-3"
              text-color="black"
              size="md"
              @click="dialogFilter = false"
            />
          </div>
        </div>
        <q-separator color="black" />

        <div>
          <div class="row items-center q-py-sm">
            <div class="q-pl-md">
              <q-btn
                unelevated
                label="Date Range"
                icon="date_range"
                color="grey-3"
                text-color="black"
                size="md"
                @click="dialogDates = true"
              />
            </div>
            <div class="col text-center text-subtitle1 text-weight-medium">
              {{ datesSelectedStr }}
            </div>
          </div>
        </div>
        <q-separator color="black" />

        <div
          :style="`max-height: ${store.state.pageHeight - 152}px`"
          class="scroll"
        >
          <div class="row items-center q-px-md q-py-sm">
            <div>
              <q-checkbox
                dense
                v-model="pondSelectAll"
                label="Select All - Ponds"
                class="text-bold"
                color="primary"
                @input="setPondCheckboxesAll"
              />
            </div>
          </div>
          <q-separator />

          <div class="q-pb-md">
            <div
              class="q-px-md q-pt-xs"
              v-for="pond in pondCheckboxes"
              :key="pond.id"
            >
              <q-checkbox
                dense
                v-model="pond.checked"
                :label="pond.name"
                color="primary"
              />
            </div>
          </div>

          <!-- Treatment Filter -->
          <div v-if="currentView === 'Treatments'" class="q-pb-md">
            <q-separator />
            <div class="q-px-md q-py-sm">
              <q-checkbox
                dense
                v-model="treatmentSelectAll"
                label="Select All - Treatments"
                class="text-bold"
                color="primary"
                @input="setTreatmentCheckboxesAll"
              />
            </div>
            <q-separator />

            <div class="q-pb-sm">
              <div
                class="q-px-md q-pt-xs"
                v-for="treatment in treatmentCheckboxes"
                :key="treatment.id"
              >
                <q-checkbox
                  dense
                  v-model="treatment.checked"
                  :label="treatment.name"
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

    <q-dialog full-height persistent position="top" v-model="dialogDates">
      <DatePicker
        :dateRangeSelection="dateRangeSelection"
        @datesSelected="datesSelected"
      />
    </q-dialog>
  </div>
</template>

<script>
import Treatments from '@/components/reports/Treatments.vue';
import Flavors from '@/components/reports/Flavors.vue';
import Oxygen from '@/components/reports/Oxygen.vue';
import FeedDetail from '@/components/reports/FeedDetail.vue';
import DatePicker from '@/components/general/DatePicker.vue';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth,
  smartCompare
} from '@/lib/helpers';
import { date } from 'quasar';

export default {
  name: 'CalendarPage',
  components: {
    DatePicker,
    Treatments,
    Flavors,
    Oxygen,
    FeedDetail
  },
  data() {
    return {
      currentView: 'Treatments',
      dateRangeSelection: 'currYear',
      datesSelectedCopy: {
        from: 0,
        to: 0,
        dateRangeSelected: ''
      },
      datesSelectedStr: '',
      dialogDates: false,
      dialogFilter: false,
      pondSelectAll: true,
      pondCheckboxes: [],
      store,
      treatmentCheckboxes: [],
      treatmentSelectAll: true
    };
  },
  mounted() {
    this.setPondCheckboxes();
    this.setTreatmentCheckboxes();
    this.setCurrYear();
  },
  methods: {
    datesSelected(dates) {
      this.dateRangeSelection = dates.dateRangeSelected;
      this.datesSelectedCopy = cloneObj(dates);
      const fromStr = date.formatDate(dates.from, 'MM/DD/YY');
      const toStr = date.formatDate(dates.to, 'MM/DD/YY');
      this.datesSelectedStr = `${fromStr} - ${toStr}`;

      this.dialogDates = false;
    },
    setCurrYear() {
      const currYear = new Date().getFullYear();
      const from = new Date(currYear, 0, 1).getTime();
      const to = new Date(currYear, 11, 31).getTime();

      this.datesSelectedStr = `${date.formatDate(
        from,
        'MM/DD/YY'
      )} - ${date.formatDate(to, 'MM/DD/YY')}`;
      this.dateRangeSelection = 'currYear';
      this.datesSelectedCopy = {
        from,
        to,
        dateRangeSelected: 'currYear'
      };
    },
    setLast7Days() {
      const to = date.formatDate(new Date(), 'YYYY/MM/DD');
      const from = date.formatDate(
        date.subtractFromDate(new Date(to), { days: 6 }),
        'YYYY/MM/DD'
      );

      this.datesSelectedStr = `${date.formatDate(
        from,
        'MM/DD/YY'
      )} - ${date.formatDate(to, 'MM/DD/YY')}`;
      this.dateRangeSelection = 'prev7';
      this.datesSelectedCopy = {
        from,
        to,
        dateRangeSelected: 'prev7'
      };
    },
    setPondCheckboxes() {
      this.pondCheckboxes = [];
      for (const pond of store.state.farm.ponds) {
        this.pondCheckboxes.push({
          name: pond.name,
          id: pond.id,
          checked: true
        });
      }
    },
    setPondCheckboxesAll() {
      for (const pond of this.pondCheckboxes) {
        pond.checked = this.pondSelectAll;
      }
    },
    setTreatmentCheckboxes() {
      this.treatmentCheckboxes = [];
      for (const treatment of store.state.farm.treatments) {
        this.treatmentCheckboxes.push({
          name: treatment.name,
          checked: true
        });
      }
    },
    setTreatmentCheckboxesAll() {
      for (const treatment of this.treatmentCheckboxes) {
        treatment.checked = this.treatmentSelectAll;
      }
    }
  },
  computed: {
    PondList() {
      const ponds = [];
      for (const pond of store.state.farm.ponds) {
        ponds.push({
          name: pond.name,
          id: pond.id
        });
      }

      return ponds;
    },
    PondFilter() {
      const ponds = [];
      for (const pond of this.pondCheckboxes) {
        if (pond.checked) {
          ponds.push(pond.id);
        }
      }

      return ponds;
    },
    TreatmentList() {
      const treatments = [];
      for (const treatment of store.state.treatments) {
        treatments.push({
          name: treatment.name,
          id: treatment.id
        });
      }

      return treatments;
    },
    TreatmentFilter() {
      const treatments = [];
      for (const treatment of this.treatmentCheckboxes) {
        if (treatment.checked) {
          treatments.push(treatment.name);
        }
      }

      return treatments;
    }
  },
  watch: {
    PondList() {
      this.setPondCheckboxes();
    }
  }
};
</script>

<style></style>
