<template>
  <div>
    <div class="row items-center q-py-xs q-px-sm">
      <div class="col col-sm-auto" style="width: 120px">Date</div>
      <div class="col col-sm-auto" style="width: 150px">Handheld</div>
      <div class="col col-sm-auto" style="width: 150px">Monitor</div>
      <div class="col">Memo</div>
    </div>
    <q-separator />

    <div
      :style="`height: ${store.state.pageHeight - 133}px`"
      class="scroll q-pb-md"
    >
      <div v-for="(pond, index) in OxygenRecords" :key="pond.id">
        <div class="bg-grey-2 text-subtitle1 text-bold q-px-sm">
          {{ pond.name }}
        </div>
        <div>
          <q-separator />
          <div v-for="oxygen in pond.oxygenRecords" :key="oxygen.id + 'oxygen'">
            <div class="row q-px-sm">
              <div class="col col-sm-auto text-caption" style="width: 120px">
                {{ oxygen.date }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ oxygen.handheld }}
              </div>
              <div class="col col-sm-auto text-caption" style="width: 150px">
                {{ oxygen.monitor }}
              </div>
              <div class="col text-caption">{{ oxygen.memo }}</div>
            </div>
          </div>
          <q-separator v-if="index < OxygenRecords.length - 1" />
        </div>

        <q-separator />
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'TreatmentReports',
  props: {
    dates: {
      type: Object,
      required: true
    },
    pondFilter: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      store
    };
  },
  mounted() {},
  methods: {},
  computed: {
    OxygenRecords() {
      const start = parseInt(date.formatDate(this.dates.from, 'X'));
      let end = date.endOfDate(this.dates.to, 'day');
      end = parseInt(date.formatDate(end, 'X'));

      const ponds = [];

      for (const pond of store.state.farm.ponds) {
        if (!this.pondFilter.includes(pond.id)) {
          continue;
        }

        const pondObj = {
          name: pond.name,
          id: pond.id,
          oxygenRecords: []
        };

        for (const oxygen of pond.oxygenRecords) {
          if (oxygen.date < start || oxygen.date > end) {
            continue;
          }

          const oxygenObj = {
            date: date.formatDate(oxygen.date * 1000, 'MM/DD/YY'),
            id: oxygen.id,
            memo: oxygen.memo,
            handheld: oxygen.handheld_reading,
            monitor: oxygen.monitor_reading
          };

          pondObj.oxygenRecords.push(oxygenObj);
        }

        ponds.push(pondObj);
      }

      return ponds;
    }
  }
};
</script>

<style scoped></style>
